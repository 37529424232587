
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxCustomRule,
  DxRequiredRule
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import notify from 'devextreme/ui/notify';
import { useRouter, useRoute } from 'vue-router';
import { ref, reactive } from "vue";

import auth from "../auth";

export default {
components: {
    DxForm,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxCustomRule,
    DxLoadIndicator
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const recoveryCode = ref("");
    const loading = ref(false);
    const formData = reactive({
      password:""
    });

    recoveryCode.value = route.params.recoveryCode;

    async function onSubmit() {
      const { password } = formData;
      loading.value = true;
  
      const result = await auth.changePassword(password, recoveryCode.value);
      loading.value = false;
  
      if (result.isOk) {
        router.push("/login-form3");
      } else {
        notify(result.message, 'error', 2000);
      }
    }

    function confirmPassword (e) {
      return e.value === formData.password;
    }

    return {
      loading,
      formData,
      onSubmit,
      confirmPassword
    }
  }
}
